import React, {useRef}from 'react';

import Layout from '../layouts/es.jsx';
import Information from '@components/pages/contact/Information';
import Form from '@components/pages/contact/Form';
import Seo from "../components/seo";
import {graphql, useStaticQuery} from "gatsby";
import ModalWantThanks from '@components/Modals/ModalWant/ModalWantThanks.jsx';

const ContactPage = () => {
  const sendData = (e) => {
    e.preventDefault();
    console.error("Error");
  }
  const {wpPage}  = useStaticQuery(graphql`
      query ContactUsSpanish {
        wpPage(slug: {eq: "contactanos"}) {
          id
          date
          title
          slug
          status
          featuredImage {
            node {
              sourceUrl
            }
          }
          language {
            slug
            name
            locale
            id
            homeUrl
            code
          }
          content
          seo {
            canonical
            metaDesc
            opengraphDescription
            opengraphImage {
              altText
              link
            }
            opengraphModifiedTime
            opengraphPublishedTime
            opengraphSiteName
            opengraphPublisher
            opengraphTitle
            opengraphType
            opengraphUrl
            schema {
              raw
            }
            title
            twitterDescription
            twitterImage {
              altText
              link
            }
            twitterTitle
          }
        }
      }
      `);

      const modalWantThanksRef = useRef();
      const openModal = (e) => {
        modalWantThanksRef.current.openModal();
      };

  return (
    <>
      <Seo 
        seo={wpPage.seo}
        author={false}
        lang={wpPage.language.locale}
        thumbnail={wpPage.featuredImage ? wpPage.featuredImage.node.sourceUrl : ''}
        pathname="/contacto"/>
      <Layout pageId="contact" scrollTop={false}>
        <div className="container">
          <div className="content">
            <div className="information">
              <Information />
            </div>
            <Form thanksModal={openModal}/>
          </div>
        </div>
        <ModalWantThanks modalWantThanksRef={modalWantThanksRef}/>
      </Layout>
    </>
  );
};

export default ContactPage;
